/*
jQuery plugin to wrap navigation links under a more menu
if there is not enough space available to show all navigation items in one row.

Created by - Alok Jain
Last modified: 08 July 2020
*/

(function($) {

    $.fn.navwrap = function(opt) {

        // select existing navigation list
        var nav = $(this);
        nav.show();

        //Set initial variables
        var availableSpace = nav.outerWidth();
        var list = 0;
        var countedSpace = 0;
        var navItems = $(nav).html();

        function countSpace() {
            $(nav).find('li').each(function(){
                var current = $(this);
                countedSpace = countedSpace+current.outerWidth();
                if (countedSpace < availableSpace) {
                    list++;
                }
            })
        }
        countSpace();

        function createSubMenu() {
            if (countedSpace > availableSpace) {
                var more = '<li class="nav-wrap" aria-haspopup="true" aria-expanded="false"><a href="#">More</a></li>';
                nav.prepend( more ); //insert sub-menu

                var showInList = list + 1; //Space available for xx no. of items.

                var newList = [];
                // Start at 2, don't include dynamically added more link.
                for (i = 2; i <= showInList; i++) {
                    newList.push($(nav).find("li:nth-child("+i+")"));
                }

                newList.push(more);

                var childList = [];
                for (i = showInList; i <= nav.children().length; i++) {
                    childList.push($(nav).find("li:nth-child("+i+")"));
                }

                nav.html(newList);

                var ulChildren = $("<ul/>").append(childList);
                $(nav).find("li:last-child").append(ulChildren);

                $(nav).find("li:last-child ul li").last().addClass('lastMenuItem');
            }
        }
        createSubMenu();

        //Browser Resize
        $(window).on('resize', function(){
            //Reset Navigation
            $(nav).empty().html(navItems);
            availableSpace = nav.outerWidth();
            list = 0;
            countedSpace = 0;

            countSpace();
            createSubMenu();
        });

        //Toggle aria attributes on mouseover/mouseout
        $('body').on('mouseover', '.nav-wrap', function(event) {
            $(this).attr('aria-expanded', 'true');
        }).on('mouseout', '.nav-wrap', function(event) {
            $(this).attr('aria-expanded', 'false');
        });

        //Open subMenu on tab press
        $('body').on('keydown', '.nav-wrap > a', function(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode == 9) {
                $(this).closest('.nav-wrap').attr('aria-expanded', 'true');
                $(this).closest('.nav-wrap').addClass('open');
            }
        });

        //Close subMenu on shift+tab press
        $('body').on('keydown', '.nav-wrap > a', function(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode == 9) {
                if(e.shiftKey) {
                    $(this).closest('.nav-wrap').attr('aria-expanded', 'false');
                    $(this).closest('.nav-wrap').removeClass('open');
                }
            }
        });

        //Close subMenu on tab press on last menu item
        $('body').on('keydown', '.lastMenuItem', function(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode == 9) {
                $(this).closest('.nav-wrap').attr('aria-expanded', 'false');
                $(this).closest('.nav-wrap').removeClass('open');
            }
        });

        //Close menu if clicked outside the menu
        $(document).on('click', function(e) {
            if(!($(e.target).parents('.nav-wrap').length)) {
                $('.nav-wrap').attr('aria-expanded', 'false');
                $('.nav-wrap').removeClass('open');
            }
        });

        return this;
    }
}(jQuery));
